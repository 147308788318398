import React from 'react'

const ChatSVG = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                // width="891.3"
                // height="745.2"
                viewBox="0 0 891.29496 745.19434"
                //   xmlns:/</>xlink="http://www.w3.org/1999/xlink"
            >
                <path
                    d="M 778.6 250.4 h -4 V 140.8 a 63.4 63.4 0 0 0 -63.4 -63.4 H 479.2 a 63.4 63.4 0 0 0 -63.4 63.4 v 601 a 63.4 63.4 0 0 0 63.4 63.4 H 711.2 a 63.4 63.4 0 0 0 63.4 -63.4 V 328.3 h 4 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#3f3d56"
                />
                <path
                    d="M 761.2 141.2 v 600.1 a 47.4 47.4 0 0 1 -47.4 47.4 h -233.2 a 47.4 47.4 0 0 1 -47.4 -47.4 v -600.1 a 47.4 47.4 0 0 1 47.4 -47.4 h 28.3 a 22.5 22.5 0 0 0 20.8 31 h 133 a 22.5 22.5 0 0 0 20.8 -31 h 30.3 A 47.4 47.4 0 0 1 761.2 141.2 Z"
                    transform="matrix(1,0,0,1,-154.4,-77.4)"
                    fill="#000000"
                />
                <path
                    d="M 686 400 q -2.3 1.2 -4.7 2.3 q -2.2 1 -4.4 1.9 c -0.6 0.2 -1.1 0.4 -1.7 0.6 a 89.5 89.5 0 0 1 -13.6 3.8 q -3.4 0.7 -7 1.1 q -2.9 0.3 -5.9 0.5 c -1.4 0.1 -2.8 0.1 -4.2 0.1 a 89.8 89.8 0 0 1 -16.8 -1.6 c -1.4 -0.3 -2.9 -0.6 -4.3 -0.9 a 88.8 88.8 0 0 1 -19.7 -7.3 c -0.6 -0.3 -1.1 -0.6 -1.7 -0.9 c -0.8 -0.4 -1.6 -0.9 -2.4 -1.4 c 0.4 -0.5 0.8 -1.1 1.2 -1.6 a 53 53 0 0 1 78.9 -4.1 a 54.3 54.3 0 0 1 5.1 5.9 C 685.3 398.9 685.6 399.4 686 400 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#6c63ff"
                />
                <circle cx="492.1" cy="234.8" r="43.9" fill="#2f2e41" />
                <circle
                    cx="642.5"
                    cy="327.5"
                    r="32.7"
                    transform="translate(-232.7,270.9) rotate(-28.7)"
                    fill="#a0616a"
                />
                <path
                    d="M 676.8 306.9 a 44.4 44.4 0 0 1 -25.4 7.9 a 27.2 27.2 0 0 0 10.8 4.4 a 89.6 89.6 0 0 1 -36.6 0.2 a 23.7 23.7 0 0 1 -7.7 -2.6 a 9.7 9.7 0 0 1 -4.7 -6.3 c -0.8 -4.6 2.8 -8.8 6.5 -11.6 a 47.9 47.9 0 0 1 40.2 -8 c 4.5 1.2 9 3.1 11.9 6.7 s 3.8 9.2 1 12.9 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#2f2e41"
                />
                <path
                    d="M 644.5 230.2 a 90 90 0 0 0 -46.8 166.8 l 0.6 0.3 q 0.7 0.4 1.4 0.8 c 0.8 0.5 1.6 0.9 2.4 1.4 c 0.6 0.3 1.1 0.6 1.7 0.9 a 88.8 88.8 0 0 0 19.7 7.3 c 1.4 0.3 2.8 0.7 4.3 0.9 a 89.8 89.8 0 0 0 16.8 1.6 c 1.4 0 2.8 0 4.2 -0.1 q 3 -0.1 5.9 -0.5 q 3.5 -0.4 7 -1.1 a 89.5 89.5 0 0 0 13.6 -3.8 c 0.6 -0.2 1.1 -0.4 1.7 -0.6 q 2.3 -0.9 4.4 -1.9 q 2.4 -1.1 4.7 -2.3 a 90.8 90.8 0 0 0 37 -36 c 0 -0.1 0.1 -0.2 0.1 -0.2 a 89.3 89.3 0 0 0 9.6 -26.4 a 90.1 90.1 0 0 0 -88.4 -107.2 Z m 77.1 132.4 c -0.1 0.1 -0.1 0.3 -0.2 0.4 a 88.2 88.2 0 0 1 -36.5 35.3 q -2.3 1.2 -4.7 2.3 c -1.3 0.6 -2.6 1.1 -4 1.7 c -0.6 0.2 -1.1 0.4 -1.7 0.6 a 85.7 85.7 0 0 1 -11.7 3.4 a 84.7 84.7 0 0 1 -9 1.4 c -1.9 0.2 -3.7 0.3 -5.6 0.4 c -1.2 0.1 -2.4 0.1 -3.6 0.1 a 86.8 86.8 0 0 1 -16.2 -1.5 a 85.6 85.6 0 0 1 -9.6 -2.4 a 88.5 88.5 0 0 1 -14 -5.7 c -0.5 -0.3 -1 -0.5 -1.6 -0.8 c -0.7 -0.4 -1.5 -0.8 -2.2 -1.2 c -0.5 -0.3 -1.1 -0.6 -1.6 -0.9 c -0.3 -0.2 -0.6 -0.4 -0.9 -0.6 a 88.1 88.1 0 1 1 123.2 -32.5 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#3f3d56"
                />
                <path
                    d="M 624.3 268.9 c -0.5 -5 -6.6 -8 -11.3 -6.5 s -7.9 6.3 -8.8 11.2 a 16.1 16.1 0 0 0 2.2 12.1 c 2.4 3.5 6.8 5.6 10.9 4.7 c 4.7 -1 8 -5.6 8.9 -10.3 s 0 -9.6 -0.9 -14.3 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#2f2e41"
                />
                <path
                    d="M 691.2 276 c -0.5 -5 -6.6 -8 -11.3 -6.5 s -7.9 6.3 -8.8 11.2 a 16.1 16.1 0 0 0 2.2 12.1 c 2.4 3.5 6.8 5.6 10.9 4.7 c 4.7 -1 8 -5.6 8.9 -10.3 s 0 -9.6 -0.9 -14.3 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#2f2e41"
                />
                <path
                    d="M 488.9 356.1 a 4.5 4.5 0 0 1 -3.3 -1.5 L 436 300.5 a 6 6 0 0 0 -4.4 -1.9 H 169.4 a 15 15 0 0 1 -15 -15 V 189 a 15 15 0 0 1 15 -15 H 509.1 A 15 15 0 0 1 524.1 189 v 94.6 A 15 15 0 0 1 509.1 298.6 h -9.6 a 6 6 0 0 0 -6 6 v 47 a 4.5 4.5 0 0 1 -2.9 4.2 A 4.5 4.5 0 0 1 488.9 356.1 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#000000"
                />
                <path
                    d="M 488.9 356.1 a 4.5 4.5 0 0 1 -3.3 -1.5 L 436 300.5 a 6 6 0 0 0 -4.4 -1.9 H 169.4 a 15 15 0 0 1 -15 -15 V 189 a 15 15 0 0 1 15 -15 H 509.1 A 15 15 0 0 1 524.1 189 v 94.6 A 15 15 0 0 1 509.1 298.6 h -9.6 a 6 6 0 0 0 -6 6 v 47 a 4.5 4.5 0 0 1 -2.9 4.2 A 4.5 4.5 0 0 1 488.9 356.1 Z M 169.4 176 A 13 13 0 0 0 156.4 189 v 94.6 a 13 13 0 0 0 13 13 H 431.6 a 8 8 0 0 1 5.9 2.6 l 49.6 54.1 a 2.5 2.5 0 0 0 4.3 -1.7 v -47 a 8 8 0 0 1 8 -8 H 509.1 a 13 13 0 0 0 13 -13 V 189 A 13 13 0 0 0 509.1 176 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#3f3d56"
                />
                <circle cx="36.8" cy="125.2" r="13.1" fill="#6c63ff" />
                <path
                    d="M 493.8 275.3 H 184.7 a 7 7 0 1 1 0 -14 H 493.8 a 7 7 0 0 1 0 14 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#e6e6e6"
                />
                <path
                    d="M 393.1 245.5 H 184.7 a 7 7 0 1 1 0 -14 H 393.1 a 7 7 0 0 1 0 14 Z"
                    transform="matrix(1,0,0,1,-154.4,-77.4)"
                    fill="#e6e6e6"
                />
                <path
                    d="M 709.4 676.8 a 4.5 4.5 0 0 1 -2.9 -4.2 v -47 a 6 6 0 0 0 -6 -6 H 690.9 a 15 15 0 0 1 -15 -15 V 510 A 15 15 0 0 1 690.9 495 H 1030.6 a 15 15 0 0 1 15 15 v 94.6 a 15 15 0 0 1 -15 15 H 768.4 a 6 6 0 0 0 -4.4 1.9 l -49.6 54.1 a 4.5 4.5 0 0 1 -3.3 1.5 A 4.5 4.5 0 0 1 709.4 676.8 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#000000"
                />
                <path
                    d="M 709.4 676.8 a 4.5 4.5 0 0 1 -2.9 -4.2 v -47 a 6 6 0 0 0 -6 -6 H 690.9 a 15 15 0 0 1 -15 -15 V 510 A 15 15 0 0 1 690.9 495 H 1030.6 a 15 15 0 0 1 15 15 v 94.6 a 15 15 0 0 1 -15 15 H 768.4 a 6 6 0 0 0 -4.4 1.9 l -49.6 54.1 a 4.5 4.5 0 0 1 -3.3 1.5 A 4.5 4.5 0 0 1 709.4 676.8 Z M 690.9 497 A 13 13 0 0 0 677.9 510 v 94.6 A 13 13 0 0 0 690.9 617.6 h 9.6 a 8 8 0 0 1 8 8 v 47 a 2.5 2.5 0 0 0 4.3 1.7 l 49.6 -54.1 a 8 8 0 0 1 5.9 -2.6 h 262.2 a 13 13 0 0 0 13 -13 V 510 a 13 13 0 0 0 -13 -13 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#3f3d56"
                />
                <path
                    d="M 603.5 706.1 a 89.1 89.1 0 0 1 -93.7 1.5 a 54.1 54.1 0 0 1 9.4 -12.7 a 53.4 53.4 0 0 1 83.9 10.6 C 603.3 705.7 603.4 705.9 603.5 706.1 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#6c63ff"
                />
                <circle cx="398.4" cy="536.7" r="44.2" fill="#2f2e41" />
                <circle
                    cx="556.8"
                    cy="629.5"
                    r="32.9"
                    transform="translate(-417,738.7) rotate(-61.3)"
                    fill="#ffb8b8"
                />
                <path
                    d="M 522.3 608.8 a 44.7 44.7 0 0 0 25.6 7.9 a 27.4 27.4 0 0 1 -10.9 4.5 a 90.2 90.2 0 0 0 36.9 0.2 a 23.9 23.9 0 0 0 7.7 -2.6 a 9.8 9.8 0 0 0 4.8 -6.4 c 0.8 -4.6 -2.8 -8.8 -6.5 -11.6 a 48.2 48.2 0 0 0 -40.5 -8.1 c -4.5 1.2 -9.1 3.1 -12 6.8 s -3.8 9.2 -1 13 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#2f2e41"
                />
                <path
                    d="M 555.5 721.2 a 90 90 0 1 1 48.6 -14.2 A 89.9 89.9 0 0 1 555.5 721.2 Z m 0 -178 a 88 88 0 1 0 88 88 A 88.1 88.1 0 0 0 555.5 543.2 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#3f3d56"
                />
                <circle cx="563.8" cy="445.2" r="13.1" fill="#6c63ff" />
                <path
                    d="M 1020.8 595.3 H 711.7 a 7 7 0 1 1 0 -14 h 309.1 a 7 7 0 0 1 0 14 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#e6e6e6"
                />
                <path
                    d="M 920.1 565.5 H 711.7 a 7 7 0 1 1 0 -14 H 920.1 a 7 7 0 0 1 0 14 Z"
                    transform="translate(-154.4,-77.4)"
                    fill="#e6e6e6"
                />
            </svg>
        </>
    )
}

export default ChatSVG
